import React, { FunctionComponent } from 'react';

interface ButtonProps {
    label: string;
    onClick: () => void;
}

const Button: FunctionComponent<ButtonProps> = ({ label, onClick }: ButtonProps) => {
    return (
        <button className="button" onClick={onClick}>
            {label}
        </button>
    );
};

export default Button;