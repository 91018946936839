//import React from 'react';
//import { useStateValue } from '../state';
//import Link from './Link';

// type Config = {
//     keyword: string;
// };

export default function Description() {
    //const [{ config }] = useStateValue();

    return (
        <>
            <b>Solutions for a better world</b>
            {/* Visualizing <b>{`"${config.keyword}"`}</b> Google Trends with{' '} */}
            {/* <Link link="REACT_GLOBE_GITHUB" className={''}>react-globe</Link> */}
        </>
    );
}