import React, { useEffect, useRef } from 'react';
import Globe from "react-globe.gl";
import * as Three from 'three';
import Overlay from './components/Overlay';

const w = window.innerWidth;
const shiftFactor = 0.4;
const shiftAmmount = shiftFactor * w;

const globeMaterial = new Three.MeshPhongMaterial();
globeMaterial.bumpScale = 10;
new Three.TextureLoader().load('//unpkg.com/three-globe/example/img/earth-water.png', texture => {
  globeMaterial.specularMap = texture;
  globeMaterial.specular = new Three.Color('grey');
  globeMaterial.shininess = 15;
});

function App() {
  const globeEl = useRef<any>();

  useEffect(() => {
    setTimeout(() => { // wait for scene to be populated (asynchronously)
      if (globeEl.current) {
        globeEl.current.controls().autoRotate = true;
        globeEl.current.controls().autoRotateSpeed = 0.1;
        //const directionalLight = globeEl.current.scene().children.find((obj3d: { type: string; }) => obj3d.type === 'DirectionalLight');
        //directionalLight && directionalLight.position.set(1, 1, 1); // change light position to see the specularMap's effect
      }
    });
  }, []);
  
  return (
    <div className="App"
      style={{
        marginRight: `-${shiftAmmount}px`
      }}
    >
      <Globe        
        ref={globeEl}
        width={w + shiftAmmount}
        animateIn={false}
        globeMaterial={globeMaterial}
        globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
        //globeImageUrl="//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
        bumpImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
        backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
      />
      <Overlay />
    </div>
  );
}

export default App;
