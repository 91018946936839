import moment from 'moment';
import React, { useState } from 'react';

//import { useStateValue } from '../state';
import About from './About';
import Description from './Description';
import Fade from './Fade';
import Link from './Link';

export default function Overlay() {
    // const [
    //     { focusedMarker, lastUpdated, markers, start },
    //     dispatch,
    // ] = useStateValue();
    const [showAbout, setShowAbout] = useState(false);

    // const showOverlay = start && !showAbout && !focusedMarker;

    return (
        <>
            <About onHide={() => setShowAbout(false)} show={showAbout} />
            <Fade className="overlay" show={true}>
                <div className="header">
                    <div style={{marginLeft: 450, marginTop: 500}}>
                        <h1 style={{fontSize:100}}>LandSphere</h1>
                        <div style={{textAlign:'center'}} className="overlay-subtitle">
                            <Description />
                        </div>
                    </div>
                    {/* <div>
                        <Link className="nudge-right" onClick={() => setShowAbout(true)} link={'CONFIG'}>
                            About
                        </Link>
                        <Link link="GITHUB_REPO" className={''}>Github</Link>
                    </div> */}
                </div>
                <div className="content">
                    {/* TOP 5 SEARCHING CITIES */}
                    {/* {markers.slice(0, 5).map((marker) => (
                        <Link key={marker.city} className={''} link={'CONFIG'}>
                            <h2 onClick={() => dispatch({ type: 'FOCUS', payload: marker })}>
                                {marker.city} ({marker.value})
                            </h2>
                        </Link>
                    ))} */}
                </div>
                {/* <div className="footer">
                    Copyright LandSphere AB {moment().format('YYYY-MM-DD')}
                </div> */}
            </Fade>
        </>
    );
}